<ul class="layout-menu layout-menu-box-shadow {{menuMode==='static'?'static-menu':'slim-plus-menu'}}">
  <ng-container *ngIf="!globalService.userInfo||!globalService.userInfo.elevate">
    <ng-container *ngIf="menuMode==='static'">
      <ng-container *ngFor="let item of staticModel; let i = index;">
        <li app-menuitem [item]="item" [index]="i" [root]="true" *ngIf="!item.separator"></li>
        <li *ngIf="item.separator" class="p-menu-separator"></li>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="menuMode==='slim-plus'">
      <ng-container *ngFor="let item of slimPlusModel; let i = index;">
        <li app-menuitem [item]="item" [index]="i" [root]="true" *ngIf="!item.separator"></li>
        <li *ngIf="item.separator" class="p-menu-separator"></li>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="globalService.userInfo&&globalService.userInfo.elevate">
    <ng-container *ngFor="let item of model2; let i = index;">
      <li app-menuitem [item]="item" [index]="i" [root]="true" *ngIf="!item.separator"></li>
      <li *ngIf="item.separator" class="p-menu-separator"></li>
    </ng-container>
  </ng-container>

</ul>
