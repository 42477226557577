<div class="layout-topbar">
  <a class="app-logo" routerLink="/">
    <!--<img alt="app logo" [src]="logo">-->
    <p-avatar image="{{logo}}" shape="circle" styleClass="border-2 surface-border mr-2" size="xlarge"></p-avatar>
    <span class="app-name">{{'title'|translate}}</span>
  </a>
  
  <button #menubutton pButton class="p-link text-black ml-3" type="button" (click)="onMenuButtonClick()" [icon]="'pi pi-align-justify'">
    <span></span>
  </button>
  
  <ul class="topbar-menu">
    <li *ngFor="let item of tabs; let i = index">
      <a [routerLink]="item.routerLink" routerLinkActive="active-route" [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
         [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling" [preserveFragment]="item.preserveFragment!"
         [skipLocationChange]="item.skipLocationChange!" [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
        <span>{{item.label}}</span>
      </a>
      <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
    </li>
    <li *ngIf="!tabs || tabs.length === 0" class="topbar-menu-empty">
    </li>
  </ul>
  
  <!--<div class="topbar-search" [ngClass]="{'topbar-search-active': searchActive}">
    <button class="topbar-searchbutton p-link" type="button" (click)="activateSearch()">
      <i class="pi pi-search"></i>
    </button>
    
    <div class="search-input-wrapper">
            <span class="p-input-icon-right">
                <input #searchinput type="text" pInputText placeholder="Search" (blur)="deactivateSearch()" (keydown.escape)="deactivateSearch()"/>
                <i class="pi pi-search"></i>
            </span>
    </div>
  </div>-->
  
  <div class="topbar-search mr-5">
    <button class="topbar-searchbutton p-link" type="button" (click)="activateSearch()">
      <i class="pi pi-envelope" style="font-size: 1.5rem" pBadge value="{{unreadMessage.length}}"></i>
    </button>
  </div>
  
  <div class="topbar-profile">
    <button class="topbar-profile-button p-link" type="button" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
      <div class="mr-3">
        <p-avatar image="/avatar/{{globalService.userInfo.avatar}}" shape="circle" styleClass="border-2 surface-border avatar-box-shadow" *ngIf="globalService.userInfo" size="xlarge"></p-avatar>
      </div>
      <span class="profile-details">
        <span class="profile-name" *ngIf="globalService.userInfo">{{globalService.userInfo.nickName}}</span>
        <span class="profile-name text-grey" *ngIf="globalService.userInfo">{{globalService.userInfo.company}}</span>
      </span>
      <i class="pi pi-angle-down"></i>
    </button>
    <ul class="list-none p-3 m-0 border-round shadow-2 hidden absolute surface-overlay origin-top w-full sm:w-12rem mt-2 right-0 top-auto">
      <li>
        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer" (click)="setting()">
          <i class="pi pi-cog mr-3"></i>
          <span>{{'topBar.setting'|translate}}</span>
        </a>
        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer" (click)="logout()">
          <i class="pi pi-power-off mr-3"></i>
          <span>{{'topBar.logout'|translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
