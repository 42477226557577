import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LayoutService} from "./service/app.layout.service";
import {Apis} from "../services/apis";
import {HttpclientService} from "../services/http.client.service";
import {GlobalService} from "../services/global.service";
import {Router} from "@angular/router";
import {BadgeModule} from 'primeng/badge';
import {MenuService} from "./app.menu.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {
  
  menu: MenuItem[] = [];
  
  @ViewChild('searchinput') searchInput!: ElementRef;
  
  @ViewChild('menubutton') menuButton!: ElementRef;
  
  searchActive: boolean = false;
  
  overlay: any;
  
  unreadMessage = [];
  
  constructor(
    public layoutService: LayoutService,
    public globalService: GlobalService,
    private http: HttpclientService,
    private router: Router,
    private menuService: MenuService
  ) {
    this.overlay = this.layoutService.isOverlay();
  }
  
  ngOnInit(): void {
    this.http.get(Apis.user.get_findUnreadMessageByTargetUser).subscribe(data => {
      this.unreadMessage = data;
    })
  }
  
  onMenuButtonClick() {
    let menuMode = this.layoutService.config.menuMode;
    if (menuMode === 'static') {
      this.layoutService.config.menuMode = 'slim-plus';
      this.layoutService.onMenuModeChange('slim-plus');
      this.menuService.reset();
    } else {
      this.layoutService.config.menuMode = 'static';
      this.layoutService.onMenuModeChange('static');
    }
  }
  
  activateSearch() {
  
  }
  
  deactivateSearch() {
    this.searchActive = false;
  }
  
  removeTab(event: MouseEvent, item: MenuItem, index: number) {
    this.layoutService.onTabClose(item, index);
    event.preventDefault();
  }
  
  get layoutTheme(): string {
    return this.layoutService.config.layoutTheme;
  }
  
  get colorScheme(): string {
    return this.layoutService.config.colorScheme;
  }
  
  get logo(): string {
    /*const path = 'assets/layout/images/logo-';
    const logo = (this.layoutTheme === 'primaryColor' && !(this.layoutService.config.theme == "yellow")) ? 'light.png' : (this.colorScheme === 'light' ? 'dark.png' : 'light.png');
    return path + logo;*/
    
    return 'assets/layout/images/waaok.jpg';
  }
  
  get tabs(): MenuItem[] {
    return this.layoutService.tabs;
  }
  
  setting() {
    this.router.navigateByUrl('/setting/user_setting');
  }
  
  logout() {
    const url = Apis.user.post_logout;
    this.http.post(url, {}).subscribe(data => {
      this.router.navigateByUrl('/login');
    });
  }
}
