import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './layout/app.layout.component';
import {ProductListModule} from "./pages/product/product_list/product_list.module";
import {AddDemandModule} from "./pages/demand/add_demand/add_demand.module";

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {animationName: '/'}
      },
      {
        path: 'product/list',
        loadChildren: () => import('./pages/product/product_list/product_list.module').then(m => m.ProductListModule),
        data: {animationName: 'product/list'}
      },
      {
        path: 'demand/add',
        loadChildren: () => import('./pages/demand/add_demand/add_demand.module').then(m => m.AddDemandModule),
        data: {animationName: 'product/list'}
      }
    ]
  },
  {path: 'login', loadChildren: () => import('./pages/user/login/login.module').then(m => m.LoginModule)},
  {path: 'register', loadChildren: () => import('./pages/user/register/register.module').then(m => m.RegisterModule)},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
