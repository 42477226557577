import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InputTextModule} from 'primeng/inputtext';
import {SidebarModule} from 'primeng/sidebar';
import {BadgeModule} from 'primeng/badge';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RippleModule} from 'primeng/ripple';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {RouterModule} from '@angular/router';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSidebarComponent} from './app.sidebar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppConfigModule} from './config/config.module';
import {AppLayoutComponent} from './app.layout.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {StyleClassModule} from 'primeng/styleclass';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import {MenuModule} from 'primeng/menu';
import {TranslateModule} from "@ngx-translate/core";
import {SplitButtonModule} from "primeng/splitbutton";
import {ProgressBarModule} from "primeng/progressbar";
import {DialogModule} from "primeng/dialog";
import {AvatarModule} from "primeng/avatar";

@NgModule({
  declarations: [
    AppMenuitemComponent,
    AppTopBarComponent,
    AppSidebarComponent,
    AppFooterComponent,
    AppMenuComponent,
    AppLayoutComponent,
    AppBreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InputTextModule,
    InputSwitchModule,
    MenuModule,
    RouterModule,
    DropdownModule,
    SidebarModule,
    StyleClassModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,
    RouterModule,
    TooltipModule,
    AppConfigModule,
    TranslateModule,
    SplitButtonModule,
    ProgressBarModule,
    DialogModule,
    AvatarModule
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule {
}
