import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LayoutService} from './service/app.layout.service';
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../services/global.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {
  
  staticModel: any[] = [];
  
  slimPlusModel: any[] = [];
  
  model2: any[] = [];
  
  isAdmin = false;
  
  menuMode = null;
  
  subscription: Subscription;
  
  constructor(
    public layoutService: LayoutService,
    private translate: TranslateService,
    public globalService: GlobalService,
  ) {
    this.subscription = this.layoutService.menuModeChange$.subscribe(menuMode => {
      this.menuMode = menuMode;
    })
  }
  
  ngOnInit() {
    this.menuMode = this.layoutService.config.menuMode;
    
    this.staticModel = [
      {
        label: this.translate.instant('menu.dashboard'),
        icon: 'pi pi-home',
        routerLink: ['/']
      },
      {
        label: this.translate.instant('menu.product'),
        icon: 'pi pi-heart',
        routerLink: ['/product/list']
      },
      {
        label: this.translate.instant('menu.demandMenu'),
        items: [
          {
            label: this.translate.instant('menu.orderGoods'),
            routerLink: ['/demand/add']
          },
          {
            label: this.translate.instant('menu.demandOrders'),
            routerLink: ['/demand/order']
          },
          {
            label: this.translate.instant('menu.operateOrders'),
            routerLink: ['/demand/operate_order']
          },
          {
            label: this.translate.instant('menu.demandBookProduct'),
            routerLink: ['/demand/book_product']
          },
          {
            label: this.translate.instant('menu.demandReplyProduct'),
            routerLink: ['/demand/reply_product']
          },
        ]
      }
    ];
    
    this.slimPlusModel = [
      {
        label: this.translate.instant('menu.dashboard'),
        icon: 'pi pi-home',
        routerLink: ['/']
      },
      {
        label: this.translate.instant('menu.product'),
        icon: 'pi pi-heart',
        routerLink: ['/product/list']
      },
      {
        label: this.translate.instant('menu.demandMenu2'),
        icon: 'pi pi-shopping-cart',
        items: [
          {
            label: this.translate.instant('menu.orderGoods'),
            routerLink: ['/demand/add']
          },
          {
            label: this.translate.instant('menu.demandOrders'),
            routerLink: ['/demand/order']
          },
          {
            label: this.translate.instant('menu.operateOrders'),
            routerLink: ['/demand/operate_order']
          },
          {
            label: this.translate.instant('menu.demandBookProduct'),
            routerLink: ['/demand/book_product']
          },
          {
            label: this.translate.instant('menu.demandReplyProduct'),
            routerLink: ['/demand/reply_product']
          }
        ]
      }
    ];
    
    this.model2 = [
      {
        label: this.translate.instant('menu.dashboard'),
        icon: 'pi pi-home',
        routerLink: ['/']
      },
      {
        label: this.translate.instant('menu.adminProduct'),
        icon: 'pi pi-book',
        routerLink: ['/admin/product/list']
      },
      {
        label: this.translate.instant('menu.adminAddProduct'),
        icon: 'pi pi-plus',
        routerLink: ['/admin/product/add']
      }
    ];
  }
  
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
